<template>
    <p class="text">{{ $t('faq.kodi.step4') }}</p>
</template>
<script>
export default {
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
